import React from 'react'

function CmsText({ component, page_id='' }) {

    // var componentStyle = typeof component.options.background != 'undefined' ? 
    // {
    //     backgroundImage: `url(${component.options.background})`,
    //     backgroundPosition: `center`,
    //     backgroundSize: `cover`,
    //     height:'100%'
    // } : {};



    return (
        <div 
            className={`h-full text`} 
            id={`${component.id ?? ""}`}>
            <div 
                dangerouslySetInnerHTML={{ __html: component.options.content }} 
                // style={componentStyle} 
            />
        </div>
        
    )
}

export default CmsText